<template>
  <!--푸터-->
  <div id="footer">
    <div class="footer_wrap">
      <div class="footer_logo_wrap">
        <img src="../assets/img/etnerlogo_w.png">
      </div>
      <div class="location">
        <kendo-dropdownlist
            ref="cmpyMngRef"
            class="f_r w20"
            :value="'99999'"
            :data-source="mngRouteLinkDataSource"
            :data-text-field="'mngCmpyNm'"
            :data-value-field="'mngCmpyCd'"
            @change="changeCmpyMng"
        >
        </kendo-dropdownlist>
      </div>
      <div class="footer_text">
        <ul>
          <li><a @click="popup()" style="cursor: pointer;">이용약관</a></li>
          <li><a @click="fnPlcyView()" style="cursor: pointer;"><b>개인정보처리방침</b></a></li>
        </ul>
        <span> 이트너스(주)<i>|</i>대표이사 : 임각균<i>|</i>[13840] 경기도 과천시 과천대로7나길 60 과천어반허브 A동 5F<br>
        사업자번호 : 201-81-66865<i>|</i>개인정보 및 청소년 보호 책임자 : 진희창<i>|</i>대표전화 : 1533-4810<i>|</i>팩스 : 070-8806-5190<i>|</i>ⓒ 2022 etners Co.,Ltd
        </span></div>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import EtnersEfsUtil from '../common/etners.efs.util.js'
export default {
  name: 'Footer',
  computed : {
    loginUserData: function() {
      return this.$store.getters['loginUserData']
    },
    ...mapGetters(['loginUserData','loginUserToken'])
  },
  created () {
    const vm = this
    vm.mngRouteLinkDataSource = [
      {sysGbn: '', mngCmpyNm : 'Portal 메인'},
      ...vm.loginUserData.firstAceessInfoCmpyMng,
    ]
  },
  methods: {
    popup: function () {
      window.open("https://etners.com/terms/management/detail/10/01")
    },
    changeCmpyMng(e) {
      const vm = this
      let selectedRow = e.sender.select()
      let rowData = e.sender.dataItem(selectedRow)
      vm.goRouter(rowData.sysGbn, rowData)
      vm.$refs.cmpyMngRef.kendoWidget().value(99999)
    },
    goRouter: function (sysGbn, item) {

      const vm = this
      let mode = location.hostname.includes('dev') || location.hostname.includes('localhost') ? 'dev-' : ''
      let linkCmpyCd
      let domain
      switch (sysGbn) {
        case vm.customCmpyConstant.portal :
          EtnersEfsUtil.openWindowNew(this.$router.resolve({ name: "Landing", }).href)
          break
        case vm.customCmpyConstant.fms :
          domain = `${mode}fms.etnersefs.com`
          linkCmpyCd = item.mngCmpyCd
          break
        case vm.customCmpyConstant.mega :
          domain = `${mode}mega.etnersefs.com/lgn/getSSOLogin.data`
          break;
        case vm.customCmpyConstant.cd :
          domain = `${mode}cd.etnersefs.com/lgn/getSSOLogin.data`
          break;
        case vm.customCmpyConstant.visang :
          domain = `${mode}visang.etnersefs.com/lgn/getSSOLogin.data`
          break;
      }

      if(!([vm.customCmpyConstant.efs, vm.customCmpyConstant.portal, vm.customCmpyConstant.fms].includes(sysGbn))){
        window.open(`https://${domain}?loginUserToken=${vm.loginUserToken}${!!linkCmpyCd? '&linkCmpyCd='+linkCmpyCd : ''}`)
      }else if(vm.customCmpyConstant.fms === sysGbn){
        window.location.href =`https://${domain}?loginUserToken=${vm.loginUserToken}${!!linkCmpyCd? '&linkCmpyCd='+linkCmpyCd : ''}`
      }
    },
    fnPlcyView: function(){
      //window.open("/privacy_policy_20160901.html","","width=900, height=800, scrollbars=1");
      window.open("https://etners.com/terms/management/detail/20/02");
    }
  },

  data () {
    return {
      customCmpyConstant : {
        portal : '',
        efs : '99999',
        fms : '01',
        mega : '02',
        cd : '03',
        visang : '04'
      },
      mngRouteLinkDataSource : []
    }
  },
}
</script>

<style scoped>
#footer {
  height: 105px!important;
}
</style>
