import EtnersCommonUtil from "@ebmp-fe-common-module/module/src/common/etners.common.util"
import ApiUtil from '@/api/api.util'

const setEnterKeyEvent = function(targetIdArray, afterFunc){

  try {
    if(!targetIdArray || targetIdArray.length < 1) {
      console.error("targetIdArray is empty")
      return
    }
    if(!afterFunc) {
      console.error("afterFunc is empty")
      return
    }

    //엔터키 이벤트 자동 세팅
    for (const elementId of targetIdArray) {
        const element = document.getElementById(elementId)
          element.onkeyup = function (e) {
            const ENTER_KEY_CODE = 13
            const currentKeyCode = window.event.keyCode || e.which
            if (currentKeyCode === ENTER_KEY_CODE) {
              afterFunc()
            }
          }
    }
  } catch (e){
    console.error(e)
  }

}

/**
 * element keyup event multiple binding
 * @param {*} targetArray : [{ id: 'element's id attribute', whenLengthIs: 'length condition' }]
 * @param {*} afterFunc 
 * @returns 
 */
const setKeyupEvent = function(targetArray, afterFunc){

  try {
    if(!targetArray || targetArray.length < 1) {
      console.error("targetIdArray is empty")
      return
    }
    if(!afterFunc) {
      console.error("afterFunc is empty")
      return
    }

    //keyup 이벤트 자동 세팅
    for (const target of targetArray) {

      const element = document.getElementById(target.id)
      element.onkeyup = function (e) {
        
        if(e.target.value && e.target.value.length === target.whenLengthIs) {
          afterFunc()
        }
      }
    }
  } catch (e){
    console.error(e)
  }

}

/**
 * kendo-grid 내부 GET 방식의 ObjectArray params 변환 데이터를
 * API에서 인식하지 못하여, 인식할 수 있는 형태로 직접가공
 * @param {String} jsonKey,
 * @param {Object[]} jsonArray,
 * @returns {Object} result
 */
const convertJSONArrayToJSON = function (jsonKey, jsonArray) {

  if(EtnersCommonUtil.getMatchType(jsonKey) !== "STRING"){
    console.error(`TypeError : Key is not a String`)
    return
  }

  let result = {}
  for (const i in jsonArray) {
    if (EtnersCommonUtil.getMatchType(jsonArray[i]) === "OBJECT") {
      for (const key of Object.keys(jsonArray[i])) {
        result[jsonKey+'[' + i + '].' + key] = jsonArray[i][key]
      }
    }
  }
  return result
}

/**
 * Array의 rowNumber 세팅
 * @param {Object[]} array rowNumber를 추가할 Array,
 * @param {String} order 정방향(ASC), 역방향(DESC),
 * @return {Object[]} array rowNumber가 추가된 data,
 */
const setRowNumber = function (array, order = 'desc'){
  if(order.toLowerCase() === 'desc'){
    array.forEach((item, i)=>{
      item.rowNumber = array.length - i
    })
  }else if(order.toLowerCase() === 'asc'){
    array.forEach((item, i)=>{
      item.rowNumber = i + 1
    })
  }
  return array
}

// 그리드에 검색어 표시
const emphasisGridSearchText = function (searchWord, text) {
  searchWord = searchWord ?? ''
  text = text ?? ''

  let startIndex = text.toLowerCase().indexOf(searchWord.toLowerCase())
  let lastIndex = text.length
  let sliceIndex = searchWord.length

  if (startIndex < 0) {
    startIndex = 0
    sliceIndex = 0
  }
  let result = text.substr(0, startIndex)
      + '<span class="forg">' + text.substr(startIndex, sliceIndex) + '</span>'
      + text.substr(startIndex + sliceIndex, lastIndex)

  return result
}

const openWindowPopup = function (
  url,
  width = '700',
  height = '600',
  options = `top=30, left=30, width=${width}, location =no, height=${height}, status=no, menubar=no, toolbar=no, resizable=no, scrollbars=no`,
  target = Math.random()+'R'
  ){
  return window.open(url, target, options)
}

const openWindowNew = function(url) {
  window.open(url, '_blank')
}


const lookupDateTimeYYYYMMDDHHMMSS = (yyyymmdd, hhmiss) => {
  if (EtnersCommonUtil.getMatchType(yyyymmdd) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  if (EtnersCommonUtil.getMatchType(hhmiss) != 'STRING') {
    throw new Exception('잘못된 값입니다')
  }

  let yyyy = Number(yyyymmdd.substring(0, 4))
  let mm = Number(yyyymmdd.substring(4, 6)) - 1
  let dd = Number(yyyymmdd.substring(6, 8))

  let hh = hhmiss.substring(0, 2)
  let mi = hhmiss.substring(2, 4)
  let ss = hhmiss.substring(4, 6)

  return new Date(yyyy, mm, dd, hh, mi, ss)
}

const fileDownload = async function(url, params = {}, isShowLoadingBar = true) {
  try {
    await ApiUtil.fileDownload(url, params, isShowLoadingBar).then(function (response){
      const blob = response.data

      if(blob.type === 'application/octet-stream'){
        const fileUrl = window.URL.createObjectURL(blob)
        const link = document.createElement("a")

        const extractDownloadFilename = (response) => {
          const disposition = response.headers["content-disposition"]
          if(EtnersCommonUtil.isNotEmpty(disposition)) {
            let fileName = disposition.match(/filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/)[1].replace(/[\"]/g, "")
            return decodeURI(escape(fileName))
          }else {
            return ''
          }
        };

        link.href = fileUrl
        link.download = extractDownloadFilename(response)
        link.click()
        link.remove()

        window.URL.revokeObjectURL(fileUrl)
      }else {
        alert("파일 다운로드 실패")
      }
    })
  }catch (e){
    console.error(e)
  }
}

export default {
  setEnterKeyEvent,
  setKeyupEvent,
  convertJSONArrayToJSON,
  setRowNumber,
  emphasisGridSearchText,
  openWindowPopup,
  openWindowNew,
  lookupDateTimeYYYYMMDDHHMMSS,
  fileDownload
}
